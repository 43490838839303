"use client";
import { useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { Input } from "@/components/ui/input";
import { cn } from "@/components/utils";

type ChatInputProps = {
  onSend: (message: string) => void;
  defaultValue: string
  disabled?: boolean;
  placeholder?: string;
  className?: string;
};

export default function ChatInput({
  onSend,
  className = "",
  defaultValue = "",
  disabled = false,
  placeholder = undefined,
}: ChatInputProps) {
  const [message, setMessage] = useState(defaultValue);

  const send = () => {
    if (message.length < 1) return;
    setMessage("");

    onSend(message);
  };

  return (
    <div className={cn("flex gap-1 text-lg bg-white rounded py-1 md:w-[60vw] ", className, {
    })}>
      <Input
        placeholder={placeholder}
        disabled={disabled}
        className={cn("border-slate-600 border rounded  p-2 placeholder-slate-600")}
        onKeyDown={(e) => { if (e.key === "Enter" && !disabled) send() }}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <button
        disabled={disabled}
        className="bg-ae text-slate-100 py-2 px-4 rounded"
        onClick={send}
      >
        <AiOutlineSend />
      </button>
    </div>
  );
}
