"use client";
import { useRouter } from "next/navigation";
import ChatInput from "./chat/components/input/ChatInput";

export default function HomeChatInput() {
  const router = useRouter();
  return (<div>
    <ChatInput
      defaultValue=""
      className="w-[80vw] sm:w-[70vw] md:w-[60vw] xl:w-[40vw]"
      placeholder="Wie funktioniert ein Large Language Model?"
      onSend={(s) => router.push(`/chat?s=${encodeURIComponent(s)}`)}
    />
  </div>
  );
}
